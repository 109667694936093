import * as angular from "angular";
import { $uiRouter } from "Bootstrap/angular";
import AccountController from "Account/AccountController";
import ResetPasswordController from "Account/ResetPasswordController";
import * as loginTemplate from "Account/partials/login.html";
import * as resetPassTemplate from "Account/partials/resetPass.html";

class Account {
  constructor() {
    const routes: any[] = [
      {
        name: "login",
        url: "/login",
        parent: "masterPage",
        template: `${loginTemplate}`,
        controller: AccountController,
        controllerAs: "$ctrl",
        data: {
          roles: [],
          noLogin: true
        }
      },
      {
        name: "resetPass",
        url: "/resetPass/:hash",
        parent: "masterPage",
        template: `${resetPassTemplate}`,
        controller: ResetPasswordController,
        controllerAs: "$ctrl",
        data: {
          roles: [],
          noLogin: true
        }
      },
      {
        name: "logout",
        url: "/logout",
        parent: "masterPage",
        template: `${loginTemplate}`,
        controller: AccountController,
        controllerAs: "$ctrl",
        data: {
          roles: [],
          noLogin: true
        }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

angular.module("serviceWebApp").run(Account);
