import { UIRouter, StateService, Transition } from "@uirouter/core";
import { $uiRouter, $translate } from "Bootstrap/angular";
import { when } from "mobx";
import { UserService, SamskipNotify, TranslationService } from "Services";
import { WindowUtilities, UtilityFunctions } from "Utilities";
import LoadingTextModalService from "Services/LoadingTextModalService";

import * as imageTemplate from "Account/partials/image.html";

declare var GlobalConfig: GlobalConfig;

const USERAGREEMENTURL = {
  is:
    "https://www.samskip.is/wp-content/uploads/2019/10/notendasamningur_thjonustuvefur.pdf",
  en:
    "https://www.samskip.is/wp-content/uploads/2019/10/notendasamningur_thjonustuvefur_en.pdf"
};

class AccountController {
  private loggingIn: boolean = false;
  private loginLabel: UserLogin = <UserLogin>{};
  private user: UserLogin = <UserLogin>{};
  private userResetPass: UserResetPassword = <UserResetPassword>{};

  private $state: StateService;
  private cardRotated: boolean;
  private oldServiceWebUrl: string = GlobalConfig.ENV.OldServiceWeb;

  static $inject: string[] = ["$scope", "$transition$"];
  constructor($scope: any, $transition$: Transition) {
    this.$state = $uiRouter.stateService;
    const currentState = $transition$.to().name;

    if (UserService.isAuthenticated()) this.$state.go("home");

    UtilityFunctions.smallSpinnerPos("smallSpinner", "50%", "50%", "#ffffff");

    when(
      () => UserService.isLoginInProgress(),
      () => {
        this.loggingIn = true;
      }
    );

    this.spawnImage();

    $scope.$on("$destroy", () => {
      const imageEl = document.getElementById("image-wrapper");

      if (imageEl) imageEl.innerHTML = "";
    });
  }

  /**
   * Login the user
   */
  login = () => {
    this.loggingIn = true;
    UserService.login(this.user)
      .then((userProfile: UserProfile) => this.$state.go("home"))
      .catch(() => {
        this.loggingIn = false;
      });
  };

  /**
   * Restart user pass
   */
  resetPass = () => {
    const translation = TranslationService.translate(
      "TEXT_RESETPASSCONFIRMEMAIL"
    );
    const message = `${translation} <b> ${this.userResetPass.Email}</b>`;

    UserService.resetPassEmail(this.userResetPass.Email).then(
      () => {
        SamskipNotify.displaySuccess(message);

        this.$state.go("login");
      },
      () => {
        SamskipNotify.displayError("TEXT_FAILEDTOSENDRESETPASS");
      }
    );
  };

  /**
   * Open up Samskip service web application form (PDF) based on
   * current selected language
   */
  openServiceWebApplicationForm = () => {
    const language = TranslationService.getSelectedLanguage();
    WindowUtilities.openPopup(USERAGREEMENTURL[language], "Application");
  };

  /**
   * Rotate card
   */
  rotateCard = () => {
    this.cardRotated = !this.cardRotated;
  };

  spawnImage = () => {
    const imageEl = document.getElementById("image-wrapper");

    if (imageEl) imageEl.innerHTML += imageTemplate;
  };
}

export default AccountController;
