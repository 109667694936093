import { UIRouter, StateService, Transition } from "@uirouter/core";
import { UtilityFunctions } from "Utilities";
import { UserService, SamskipNotify } from "Services";
import { $uiRouter } from "Bootstrap/angular";

class ResetPasswordController {
  static $inject: string[] = ["$transition$"];

  private userResetPass: UserResetPassword = <UserResetPassword>{};
  private $state: StateService;
  private resetPassHash: string;

  constructor(private $transition$: Transition) {
    const $state: StateService = $uiRouter.stateService;
    const currentState: string = $transition$.to().name || "";
    const currentStateParams: any = $transition$.params();

    this.$state = $state;
    this.resetPassHash = currentStateParams.hash;

    UserService.validateHash(this.resetPassHash).then(
      () => {
        // do nothing
      },
      () => {
        this.$state.go("login");
        SamskipNotify.displayError("TEXT_VALIDATEHASHFAILED");
      }
    );

    UtilityFunctions.smallSpinnerPos("smallSpinner", "50%", "50%", "#ffffff");
  }

  /**
   * Set users new password
   */
  setNewPass = () => {
    UserService.resetPass(
      this.resetPassHash,
      this.userResetPass.NewPassword,
      this.userResetPass.NewPasswordRepeat
    ).then(
      () => {
        SamskipNotify.displaySuccess("LABEL_PASSWORDCHANGED");
        this.$state.go("login");
      },
      () => {
        SamskipNotify.displayError("TEXT_FAILEDTORESETPASS");
      }
    );
  };
}

export default ResetPasswordController;
